import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: { // A
          base: '#FFA500',
          darken1: '#cc8c25', // R
          lighten1: '#FFF4DF' // C
        },
        primaryBtnText: { // A'
          base: '#FFFFFF'
        },
        secondary: { // B
          base: '#009CA6',
          darken1: '#00005C' // D
        },
        secondaryBtnText: { // B'
          base: '#FFFFFF'
        },
        headerBack: { // E
          base: '#FFA500'
        },
        headerText: { // F
          base: '#FFFFFF'
        },
        logoutBtnText: { // G
          base: '#FFFFFF'
        },
        topSchoolNamePc: { // H
          base: '#FFA500'
        },
        topSecondaryBtn: { // I
          base: '#009CA6'
        },
        topSecondaryBtnText: { // J
          base: '#FFFFFF'
        },
        topPrimaryBtn: { // K
          base: '#FFA500'
        },
        topPrimaryBtnText: { // L
          base: '#FFFFFF'
        },
        topBtnUpText: { // M
          base: '#FFFFFF'
        },
        primaryOutLineBtnText: { // N
          base: '#FFA500'
        },
        secondaryOutLineBtnText: { // O
          base: '#FFA500'
        },
        topBtnUpTextSp: { // P
          base: '#009CA6'
        },
        topBtnBackSp: { // Q
          base: '#FFFFFF'
        },
        eventHeaderBack: {
          base: '#FFA500'
        },
        eventHeaderText: {
          base: '#FFFFFF'
        },
        eventTopBorderSub: {
          base: '#FFA500'
        },
        accent: '#FFA500', // datepickerなどの色
        formBackground: '#F6f6f6'
      },
      dark: {
        primary: '#FFA500',
        secondary: '#009CA6',
        accent: '#FFA500',
        formBackground: '#F6f6f6'
      }
    }
  }
})
